import { useEffect } from 'react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { UsTermsPageQuery } from '../../../generated/types';
import { navigateToLocalizedPath } from '../../components/i18n/utils';
import { HomepageUsTemplate } from '../../components/templates/HomepageUsTemplate';
import { CookieTermsContent } from '../../modules/legal/CookiesTermsContent';

type PpTermsPage = PageProps<UsTermsPageQuery>;

function TermsPage({ data: { content } }: PpTermsPage): React.ReactElement {
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  return (
    <HomepageUsTemplate seo={content?.frontmatter?.seo}>
      <CookieTermsContent content={content} sx={{ paddingBottom: 4, paddingTop: 10 }} />
    </HomepageUsTemplate>
  );
}

export const query = graphql`
  query UsTermsPage {
    content: markdownRemark(frontmatter: { layout: { eq: "terms" }, locale: { eq: "en" } }) {
      rawMarkdownBody
      frontmatter {
        locale
        seo {
          title
          description
          image
        }
        title
        date(formatString: "MM DD YYYY")
        termsDescription
      }
    }
  }
`;

export default TermsPage;
