import { Container, Stack } from '@mui/material';
import type { StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

const StyledContainer = styled(Container)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 3),
  },
}));

export function CookiesTermsContainer({
  children,
  ...rest
}: PpWC & StackProps): React.ReactElement {
  return (
    <Stack alignItems="center" width="100%" {...rest}>
      <StyledContainer disableGutters maxWidth="md">
        {children}
      </StyledContainer>
    </Stack>
  );
}
