import type { ReactElement } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import type { CookiesPageQuery } from '../../../generated/types';
import { CookiesTermsContainer } from './CookiesTermsContainer';

type PpCookieTermsContent = {
  content: CookiesPageQuery['content'];
  sx: SxProps<Theme>;
};

export function CookieTermsContent({ content, sx }: PpCookieTermsContent): ReactElement {
  return (
    <CookiesTermsContainer sx={sx}>
      <Typography component="h1" variant="subHeadingL">
        {content?.frontmatter?.title}
      </Typography>
      {content?.rawMarkdownBody && <Markdown>{content?.rawMarkdownBody}</Markdown>}
      <Typography component="span" variant="caption">
        Last updated on {content?.frontmatter?.date}
      </Typography>
    </CookiesTermsContainer>
  );
}
